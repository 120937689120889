import React from 'react';
import Layout from '../components/Layout';
import TitleSection from '../components/TitleSectionFB';
import HeaderFB from '../components/HeaderFB';
import Albums from '../components/Albums'
import GalleryFB from '../components/GalleryFB';
import SpotifyFB from '../components/SpotifyFB';
import YoutubeFB from '../components/YoutubeFB';
import FooterFB from '../components/FooterFB';

const IndexPage = () => (
  <Layout>
    <HeaderFB/>
    <TitleSection/>
    <Albums/>
    <GalleryFB/>
    <SpotifyFB/>
    <YoutubeFB/>
    <FooterFB/>
  </Layout>
);

export default IndexPage;
