import React from 'react';

const Spotify = () => {
  return (
    <section id="spotify" className="g-py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 text-center g-mb-30 g-mb-0--lg">
              <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-30">
                <h3 className="h3 g-letter-spacing-5 g-font-size-12 g-font-weight-400 g-color-primary g-mb-25">On Spotify</h3>
              </div>

              <p className="g-mb-30">Only two of our albums are on Spotify. Birthday and New Religion. Published by Poko Records which was later acquired by Universal.</p>
            </div>

            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-6 text-center g-mb-30 g-mb-0--md">
                  <iframe src="https://embed.spotify.com/?uri=spotify%3Aalbum%3A2atIzsuGQV91ZOzu9IUE7P" height="400" frameBorder="0" allowtransparency="true" title="birthday"></iframe>
                </div>

                <div className="col-md-6 text-center g-mb-30 g-mb-0--md">
                  <iframe src="https://embed.spotify.com/?uri=spotify%3Aalbum%3A0m9icpFDz8JtZoUiktmOa1" height="400" frameBorder="0" allowtransparency="true" title="new religion"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Spotify;