import React from 'react';

const Youtube = () => {
  return (
    <section id="youtube" className="g-theme-bg-blue-dark-v1  g-py-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 text-center g-mb-30 g-mb-0--lg">
            <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-30">
              <h3 className="h3 g-letter-spacing-5 g-font-size-12 g-font-weight-400 g-color-primary g-mb-25">On Youtube</h3>
            </div>

            <p className="g-mb-30">There are more videos on channel <a href="https://www.youtube.com/user/jorgemhnet" className="nav-link p-0 active">mieshukka.net</a></p>
            <p>Please subscribe.</p>
          </div>

          <div className="col-lg-10">
            <div className="row">
              <div className="col-md-6 text-center g-mb-30 g-mb-0--md">
                  <iframe width="500" height="281" src="https://www.youtube.com/embed/AvQAbX-dhGU" frameBorder="0" title="bloody mary" allow="autoplay; encrypted-media" allowFullScreen></iframe>
              </div>

              <div className="col-md-6 text-center g-mb-30 g-mb-0--md">
                  <iframe width="500" height="281" src="https://www.youtube.com/embed/1V2IIYzH3Kw" frameBorder="0" title="domestic" allow="autoplay; encrypted-media" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Youtube;