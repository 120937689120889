import React from 'react';

const HeaderFB = (props) => {
  
  return (
    <header id="js-header" className="u-header u-header--sticky-top u-header--change-appearance"
              data-header-fix-moment="100">
        <div className="dark-theme u-header__section g-bg-black-opacity-0_4 g-transition-0_3 g-py-8 g-py-17--md"
            data-header-fix-moment-exclude="dark-theme g-bg-black-opacity-0_4 g-py-17--md"
            data-header-fix-moment-classes="light-theme u-theme-shadow-v1 g-bg-white g-py-10--md">
        <nav className="navbar navbar-expand-lg p-0 g-px-15">
          <div className="container g-pos-rel">
            
            <div className="collapse navbar-collapse align-items-center flex-sm-row" id="navBar">
              <ul className="js-scroll-nav navbar-nav align-items-lg-center text-uppercase g-font-weight-700 g-letter-spacing-1 g-font-size-12 g-pt-20 g-pt-0--lg mx-auto"
                  data-splitted-breakpoint="992">
                <li className="nav-item g-mr-30--lg g-mb-7 g-mb-0--lg active">
                  <a href="#home" className="nav-link p-0">Home <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item g-mx-30--lg g-mb-7 g-mb-0--lg">
                  <a href="#disco" className="nav-link p-0">Discography</a>
                </li>

                <li className="nav-item g-mx-30--lg g-mb-7 g-mb-0--lg">
                  <a href="#gallery" className="nav-link p-0">Gallery</a>
                </li>
                <li className="nav-item g-mx-30--lg g-mb-7 g-mb-0--lg">
                  <a href="#spotify" className="nav-link p-0">Spotify</a>
                </li>
                <li className="nav-item g-mx-30--lg g-mb-7 g-mb-0--lg">
                  <a href="#youtube" className="nav-link p-0">Youtube</a>
                </li>
                <li className="nav-item g-mx-30--lg g-mb-7 g-mb-0--lg">
                  <a href="https://www.facebook.com/faff.bey/" target="_blank" rel="noreferrer" className="nav-link p-0">Facebook page</a>
                </li>
              </ul>
            </div>

            <button className="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-15 g-right-0" type="button"
                    aria-label="Toggle navigation"
                    aria-expanded="false"
                    aria-controls="navBar"
                    data-toggle="collapse"
                    data-target="#navBar">
              <span className="hamburger hamburger--slider">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </span>
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default HeaderFB;