import React from 'react';
import BackgroundImage from '../assets/pics/fb_coverpic.jpeg';

const TitleSection = () => {
  const background = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: 'cover'
  };
  return (
    <section id="home" className="u-bg-overlay g-pos-rel g-theme-bg-blue-dark-v1-opacity-0_8--after">
        <div className="js-carousel"
             data-autoplay="true"
             data-infinite="true"
             data-fade="true"
             data-speed="5000">
          <div className="js-slide g-bg-img-hero g-height-100vh" style={background}></div>
        </div>

        <div className="u-bg-overlay__inner g-absolute-centered w-100">
          <div className="container text-center g-max-width-750">
            <div className="text-uppercase u-heading-v2-4--bottom u-promo-title g-brd-primary">
              <h3 className="h3 g-letter-spacing-7 g-font-size-12 g-font-weight-400 g-color-white g-mb-25">Diabolus ex machina</h3>
              <h2 className="u-heading-v2__title g-line-height-1 g-letter-spacing-3 g-font-size-75 g-color-white g-mb-25">Faff-bey</h2>
              <h3 className="h3 g-letter-spacing-7 g-font-size-12 g-font-weight-400 g-color-white mb-0">Oulu, Finland</h3>
            </div>
          </div>
        </div>
      </section>
  )
}

export default TitleSection;