import React from 'react'
import mhLogo from '../assets/img/logo-red.png';
import facebookLogo from '../assets/img/facebook_icon.png';

const Footer = () => {
  return (
    <footer>
      <div className="text-center g-color-gray-dark-v5">
        <a className="d-block g-width-50 mx-auto g-mb-30 g-mt-30" href="https://www.facebook.com/faff.bey/" target="_blank" rel="noreferrer">
          <img className="img-fluid" src={facebookLogo} alt=""/>
        </a>
        <div className="container text-center g-mb-30">
          Copyright &copy; @mieshukka
          <a className="d-block g-width-220 mx-auto" href="https://www.mieshukka.net">
            <img className="img-fluid" src={mhLogo} alt="Logo for mieshukka.net"/>
          </a>
        </div>
      </div>
      
    </footer>
  );
}

export default Footer;