import React from 'react';
import Slider from "react-slick";
import FB_ace_pose2 from '../assets/pics/gallery/FB_ace_pose2.jpg';
import FB_pose_ace from '../assets/pics/gallery/FB_pose_ace.jpg';
import FB_acu_jrg from '../assets/pics/gallery/FB_acu_jrg.jpg';
import FB_acu_maike from '../assets/pics/gallery/FB_acu_maike.jpg';
import FB_backst_jrsk_kessu from '../assets/pics/gallery/FB_backst_jrsk_kessu.jpg';
import FB_live_jrg from '../assets/pics/gallery/FB_live_jrg.jpg';
import FB_live_ma_ke from '../assets/pics/gallery/FB_live_ma_ke.jpg';
import FB_live_mk_kes from '../assets/pics/gallery/FB_live_mk_kes.jpg';
import FB_maike_finger from '../assets/pics/gallery/FB_maike_finger.jpg';
import FB_pose1 from '../assets/pics/gallery/FB_pose1.jpg';

const Gallery = () => {
  const sliderSettings = {
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const images = [
    {src: FB_ace_pose2, alt: "rockband posing"},
    {src: FB_pose_ace, alt: "rockband posing more"},
    {src: FB_acu_jrg, alt: "dude with acoustic guitar"},
    {src: FB_acu_maike, alt: "another dude with acoustic guitar"},
    {src: FB_backst_jrsk_kessu, alt: "rhytm section guys backstage"},
    {src: FB_live_jrg, alt: "dude rocking live"},
    {src: FB_live_ma_ke, alt: "dudes rocking live"},
    {src: FB_live_mk_kes, alt: "more dudes rocking live"},
    {src: FB_maike_finger, alt: "dude flipping a finger"},
    {src: FB_pose1, alt: "Faff-bey"},
  ];
  return (
    <section id="gallery" className="g-theme-bg-blue-dark-v1 g-py-100">
        <div className="container text-center g-max-width-750 g-mb-70">
          <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-30">
            <h3 className="h3 g-letter-spacing-5 g-font-size-12 g-font-weight-400 g-color-primary g-mb-25">Be still my beating heart</h3>
            <h2 className="u-heading-v2__title g-line-height-1 g-letter-spacing-2 g-font-size-30 g-font-size-40--md g-color-white mb-0">Gallery</h2>
          </div>
        </div>

        <div className="container">

          
          <div className="g-mx-minus-9--sm">
            <Slider {...sliderSettings}>
            {images.map((image, i) => (
                <div key={i}>
                    <img className="w-100 g-mb-35" src={`${image.src}`} alt={`${image.alt}`}></img>
                </div>
            ))}
            </Slider>
          </div>
        </div>
      </section>
  );
}

export default Gallery;