import React from 'react';

import smParty from '../assets/pics/discs/smPartyCover.jpg';
import grave from '../assets/pics/discs/backFromTheGraveCover.jpg';
import emptyHead from '../assets/pics/discs/emptyheadCover.jpg';
import doesnt from '../assets/pics/discs/doesntCover.jpg';
import birthDay from '../assets/pics/discs/birthdayCover.jpg';
import ace from '../assets/pics/discs/aceCover.jpg';
import religion from '../assets/pics/discs/religionCover.jpg';
import should from '../assets/pics/discs/shouldCover.jpg';
import gtf from '../assets/pics/discs/GTF.jpg';


const DiscographyFB = () => {
  return (
    <section id="disco" className="g-pt-100 g-pb-100--md">
      <div className="container text-center g-max-width-750 g-mb-70">
        <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary">
          <h3 className="h3 g-letter-spacing-5 g-font-size-12 g-font-weight-400 g-color-primary g-mb-25">Our legacy 1988 - 2005</h3>
          <h2 className="u-heading-v2__title g-line-height-1 g-letter-spacing-2 g-font-size-30 g-font-size-40--md mb-0">The Discography</h2>
        </div>
      </div>

      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={smParty} alt="SM party ep"/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1988</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">S/M Party (ep)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={grave} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1988</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">Back from the grave (lp)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={emptyHead} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1989</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">Emptyhead (single)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={doesnt} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1990</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">Doesn't feel like laughing (lp) </h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={birthDay} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1991</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">Birthday (lp)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={ace} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1992</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">Ace of spades (ep)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={religion} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">1994</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">New religion (lp)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={should} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">2005</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">Should have stayed in the grave (lp)</h3>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 g-parent g-theme-bg-blue-dark-v1--hover g-transition-0_2 g-transition--ease-in">
            <img className="img-fluid w-100" src={gtf} alt=""/>

            <div className="text-center text-uppercase g-pa-30">
              <h4 className="g-letter-spacing-5 g-font-size-11 g-font-weight-400 g-color-gray-dark-v5 g-color-primary--parent-hover g-mb-10 g-transition-0_2 g-transition--ease-in">-</h4>
              <h3 className="h6 g-letter-spacing-2 g-font-weight-700 g-color-white--parent-hover mb-0 g-transition-0_2 g-transition--ease-in">* Never released *</h3>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default DiscographyFB;